import { useContext } from "react";
import { DriverEVFinderContext } from "../contexts/DriverEVFinderContext";
import { Filters } from "../../../../../../../common/components/EVFinder/Versions[B-C]/Filters";




export function DriverEvFinderFilters() {

    const { 
        filtersHandler,
        resetFiltersAndRefetch,
        vehicleSettings
    } = useContext(DriverEVFinderContext);

   

    return (
        <Filters
            filtersHandler={filtersHandler}
            resetFilters={resetFiltersAndRefetch}
            customBrands={vehicleSettings?.simulation?.filters?.default?.brands}
        />
    )


}