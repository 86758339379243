/* React imports */
import { useEffect, useMemo, useState } from "react";

/* Third party imports */
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useQuery as useRestQuery } from "react-query";

/* Local imports */

import { ReactComponent as WorkplaceIcon } from "../../../../assets/workplace-icon.svg";
import { ReactComponent as HomeIcon } from "../../../../assets/home-icon.svg";
import { ReactComponent as OptionsDropdownIcon } from "../../../../assets/options-dropdown-icon.svg";
import { homePowerList, companyPowerList } from "../../../../static/constants";
import {
  DELETE_ADDRESS,
  UPDATE_ADDRESS,
} from "../../../../services/graphql/fleet/mutations";
import DeleteModal from "./modals/DeleteAddressModal";
import { Location } from "../../../../@types/settings";
import { ChargingPowerSquare } from "./utils";
import LocationModal from "./modals/LocationModal";
import { useUserData } from "../../../../hooks/state/useUserData";
import { UserType } from "../../../../enums";
import { getDefaultFuelAndEnergyPricesForUser } from "../../../../services/rest/paymentProcess";
import { useLayout } from "../../../../hooks/state/useLayout";
import toast from "react-hot-toast";
import Dropdown from "../../../../electrify_frontend_common/components/Dropdown";
import { EnergySettingsInput } from "../../../../products/B2B/Electrify/pages/Settings/FuelAndEnergyDetails/EnergyDetails";

export default function AddressCard({
  address,
  refetch,
  locationType,
}: {
  address: Location;
  refetch: () => void;
  locationType: "HOME" | "WORKPLACE";
}) {
  const { t, i18n } = useTranslation("addresses");
  const { user } = useUserData();
  const isDriver = user?.user_type === UserType.DRIVER;
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { __typename, id, ...cleanedAddress } = address;
  const [updatedLocation, setUpdatedLocation] = useState(cleanedAddress);
  const languageCode = i18n.language;
  const regionName = new Intl.DisplayNames([languageCode], { type: "region" });
  const [energyPrice, setEnergyPrice] = useState<number | undefined | null>(
    address.energyPrice
  );
  const [energyCo2, setEnergyCo2] = useState<number | undefined | null>(
    address.energyCo2
  );
  const { currencySymbol, localeCode } = useLayout();


  useEffect(() => {
    setEnergyPrice(address.energyPrice);
    setEnergyCo2(address.energyCo2);
  }, [address.energyPrice, address.energyCo2]);

  const { data: defaultValues } = useRestQuery<{
    homeChargingPrice: number;
    homeChargingCo2Emissions: number;
    workplaceChargingPrice: number;
    workplaceChargingCo2Emissions: number;
  }>("defaultPrices", getDefaultFuelAndEnergyPricesForUser, {
    onError: (error) => {
      console.log(">>>ERR: ", error);
    },
  });

  const defaultEnergyPrice = useMemo(() => {
    return locationType == "HOME"
      ? defaultValues?.homeChargingPrice
      : defaultValues?.workplaceChargingPrice;
  }, [defaultValues]);

  const defaultEnergyEmission = useMemo(() => {
    return locationType == "HOME"
      ? defaultValues?.homeChargingCo2Emissions
      : defaultValues?.workplaceChargingCo2Emissions;
  }, [defaultValues]);

  const isHomeLocation = locationType === "HOME";

  const powerList = isHomeLocation ? homePowerList : companyPowerList;

  const [deleteAddress] = useMutation(DELETE_ADDRESS, {
    variables: {
      id: id,
    },
    onCompleted: () => {
      toast.success("Address deleted successfully");
      refetch()
    },
    onError: (error) => {
      toast.error("Failed to delete address");
      refetch();
    }
  });

  const [updateAddress] = useMutation(UPDATE_ADDRESS, {
    variables: {
      id: id,
      address: updatedLocation,
    },
    onCompleted: (data) => {
      let { __typename, id, ...cleanedAddress } = data.updateAddress;
      setUpdatedLocation(cleanedAddress);
      refetch()
      toast.success("Address updated successfully");
    },
    onError: (error) => {
      refetch();
      setEnergyCo2(address.energyCo2);
      setEnergyPrice(address.energyPrice);
      toast.error("Failed to update address");
    },
  });

  const updateAdressPower = (power: number | null) => {
    updatedLocation.power = power;
    updateAddress();
  };

  const updatePrice = () => {
    updatedLocation.energyPrice = energyPrice;
    updateAddress();
  };

  const updateCo2Emissions = () => {
    updatedLocation.energyCo2 = energyCo2;
    updateAddress();
  };

  if (openAddressModal) {
    return (
      <LocationModal
        isOpen={openAddressModal}
        locationData={address}
        locationType={locationType}
        closeModal={() => {
          refetch();
          setOpenAddressModal(false);
        }}
        isDriver={isDriver}
      />
    );
  }

  if (openDeleteModal) {
    return (
      <DeleteModal
        isOpen={openDeleteModal}
        onDelete={() => {
          deleteAddress();
          setOpenDeleteModal(false);
        }}
        closeModal={() => {
          refetch();
          setOpenDeleteModal(false);
        }}
      />
    );
  }


  return (
    <div>
      <div className="bg-Grey-background rounded py-10 px-6">
        <div className="flex flex-row w-full justify-between items-center mb-3.5">
          <div className="flex items-center">
            {isHomeLocation ? <HomeIcon /> : <WorkplaceIcon />}
            <p className="pl-2.5 text-lg font-medium">{address?.name}</p>
          </div>
          <div>
            <Dropdown
              placeholder={<OptionsDropdownIcon />}
              data={[
                {
                  key: "Edit location",
                  onClick: () => setOpenAddressModal(true),
                },
                {
                  key: "Delete location",
                  onClick: () => setOpenDeleteModal(true),
                },
              ]}
            />
          </div>
        </div>
        <hr className="border-solid border-Grey-tint mb-5" />
        <div className="mb-6">
          <p className="text-Grey-dark font-bold text-xs mb-2">
            {t("address-card.address")}
          </p>
          <p className="text-sm mb-1">
            {address?.street + " " + address?.streetNr}
          </p>
          <p className="text-sm mb-1">{address?.zip + " " + address?.city}</p>
          <p className="text-sm">{regionName.of(address?.country || "")}</p>
        </div>
        <p className="text-Grey-dark font-bold text-xs mb-4">
          {t("address-card.power")}
        </p>
        <div className={`grid grid-cols-${powerList?.length}`}>
          {powerList.map((power, idx) => (
            <div key={power} className="col-span-1">
              <ChargingPowerSquare
                value={power}
                placeholder={power?.toLocaleString(localeCode) || "x"}
                addressPower={address.power || null}
                onClick={updateAdressPower}
                classNames={`p-3 w-full ${idx === 0 ? "rounded-l-md" : " border-l-0"} ${idx === powerList.length - 1 ? "rounded-r-md" : ""}`}
                t={t}
              />
            </div>
          ))}
        </div>
      </div>
      {isDriver || locationType === "WORKPLACE" ? (
        <div className="bg-Grey-tint py-3 px-6">
          <EnergySettingsInput
            reversed
            onBlur={updatePrice}
            label={t("address-card.price")}
            units={`${currencySymbol} / kWh`}
            defaultValue={defaultEnergyPrice || 0}
            value={energyPrice}
            setValue={setEnergyPrice}
          />
          <EnergySettingsInput
            reversed
            onBlur={updateCo2Emissions}
            label={t("address-card.co2")}
            units={"g / kWh"}
            defaultValue={defaultEnergyEmission || 0}
            value={energyCo2}
            setValue={setEnergyCo2}
          />
        </div>
      ) : null}
    </div>
  );
}
