import {
	Dispatch,
	ReactElement,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { SimulationVehicleInfo } from '../@types';
import { GET_VEHICLE_SELECTED_EV } from '../services/graphql/fleet/queries/simulation';
import { ApolloQueryResult, OperationVariables, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { FleetSimulationContext } from './FleetSimulation';
import { ADD_NEW_FAVORITE_EV, REMOVE_FAVORITE_EV, UPDATE_SELECTED_EV } from '../services/graphql/fleet/mutations/simulation';
import toast from 'react-hot-toast';
import { useAlternativeVehicles } from '../products/B2B/Electrify/pages/Simulation/FleetVehicleComparatorList/AlternativesModal/hooks/useAlternativeVehicles';
import { EVAlternativesResponse } from '../@types/simulations';
import { useTranslation } from 'react-i18next';
import { GET_VEHICLE_SETTINGS } from '../services/graphql/common/queries';

export const VehicleComparatorContext = createContext<{
	vehicle: SimulationVehicleInfo;
	vehicleSettings: any;
	refetchOverviewData: any;
	planUpgradeModalOpen: boolean;
	setPlanUpgradeModalOpen: Dispatch<SetStateAction<boolean>>;
	selectedVehicleToUpgrade: string | null;
	setSelectedVehicleToUpgrade: Dispatch<SetStateAction<string | null>>;
	isAlternativesOpen: boolean;
	setIsAlternativesOpen: any;
	evDetailQueryParams: {
		vehicleId: string;
		evId: string;
	} | null;
	setEvDetailQueryParams: Dispatch<
		SetStateAction<{ vehicleId: string; evId: string } | null>
	>;
	getVehicleSelectedEv: any;
	selectedEvLoading: boolean;
	selectAlternative: (selectedEvId: string) => void;
	selectedAlternativeElectricVehicle: any;
	removeAlternative: () => void;
	refetchSimulationVehiclesInfo: () => void;
	removeFavoriteVehicle: (evIdToRemove: string) => void;
	addNewFavoriteVehicle: (evIdToAdd: string) => void;
	evFinderData: { vehicleEvAlternatives: EVAlternativesResponse; } | undefined,
	evFinderFavoritesData: { vehicleEvAlternatives: EVAlternativesResponse; } | undefined,
	loading: boolean,
	sortBy: string,
	setSortBy: Dispatch<SetStateAction<string>>,
	currentPage: number,
	setCurrentPage: Dispatch<SetStateAction<number>>,
	setFavoritesOnly: Dispatch<SetStateAction<boolean>>,
	favoritesOnly: boolean,
	alternativeEvsCount: { vehicleEvAlternativesCount: { totalCount: number, favoritesCount: number } },
	filtersHandler: any,
	refetchEvAlternatives: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{ vehicleEvAlternatives: EVAlternativesResponse }>>,
	refetchFavorites: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{ vehicleEvAlternatives: EVAlternativesResponse }>>,
	resetFiltersAndRefetch: () => void,
	recountVehicles: () => Promise<ApolloQueryResult<any>>,
	togglingFavoriteEvId: string | undefined

}>({
	vehicle: {} as SimulationVehicleInfo,
	vehicleSettings: {} as any,
	refetchOverviewData: null,
	planUpgradeModalOpen: false,
	setPlanUpgradeModalOpen: () => null,
	selectedVehicleToUpgrade: null,
	setSelectedVehicleToUpgrade: () => null,
	isAlternativesOpen: false,
	setIsAlternativesOpen: null,
	evDetailQueryParams: null,
	setEvDetailQueryParams: () => null,
	getVehicleSelectedEv: null,
	selectedEvLoading: false,
	selectAlternative: () => null,
	selectedAlternativeElectricVehicle: null,
	removeAlternative: () => null,
	refetchSimulationVehiclesInfo: () => null,
	addNewFavoriteVehicle: () => null,
	removeFavoriteVehicle: () => null,
	evFinderData: { vehicleEvAlternatives: {} as EVAlternativesResponse },
	evFinderFavoritesData: { vehicleEvAlternatives: {} as EVAlternativesResponse },
	loading: false,
	sortBy: "",
	setSortBy: () => null,
	currentPage: 0,
	setCurrentPage: () => null,
	setFavoritesOnly: () => null,
	favoritesOnly: false,
	alternativeEvsCount: { vehicleEvAlternativesCount: { totalCount: 0, favoritesCount: 0 } },
	filtersHandler: null,
	refetchEvAlternatives: () => null as any,
	refetchFavorites: () => null as any,
	resetFiltersAndRefetch: () => null as any,
	recountVehicles: () => null as any,
	togglingFavoriteEvId: undefined
});

export function VehicleComparatorContextProvider({
	vehicle,
	children,
}: {
	vehicle: SimulationVehicleInfo;
	children: ReactElement;
}) {

	const { t } = useTranslation("evFinder");

	const {
		refetchOverviewData,
		setPageSelectedEvsLoaded,
		refetchSimulationVehiclesInfo,
	} = useContext(FleetSimulationContext);

	const {
		evFinderData,
		evFinderFavoritesData,
		loading,
		sortBy,
		setSortBy,
		currentPage,
		setCurrentPage,
		setFavoritesOnly,
		favoritesOnly,
		alternativeEvsCount,
		filtersHandler,
		refetchEvAlternatives,
		refetchFavorites,
		resetFiltersAndRefetch,
		recountVehicles,
		togglingFavoriteEvId,
		setTogglingFavoriteEvId
	} = useAlternativeVehicles(vehicle.vehicleId);

	const [planUpgradeModalOpen, setPlanUpgradeModalOpen] = useState(false);
	const [selectedVehicleToUpgrade, setSelectedVehicleToUpgrade] = useState<
		string | null
	>(null);

	const [isAlternativesOpen, setIsAlternativesOpen] = useState(false);
	const [evDetailQueryParams, setEvDetailQueryParams] = useState<{
		vehicleId: string;
		evId: string;
	} | null>(null);
	const [
		selectedAlternativeElectricVehicle,
		setSelectedAlternativeElectricVehicle,
	] = useState<any | null>(null);

	const [getVehicleSelectedEv, { loading: selectedEvLoading }] =
		useLazyQuery<{
			vehicleSelectedEv: any;
		}>(GET_VEHICLE_SELECTED_EV, {
			onCompleted: ({ vehicleSelectedEv }) => {
				setSelectedAlternativeElectricVehicle(
					vehicleSelectedEv?.selectedEv,
				);
				if (setPageSelectedEvsLoaded)
					setPageSelectedEvsLoaded((count: number) => count + 1);
			},
		});

	const { data } = useQuery(GET_VEHICLE_SETTINGS, {
		variables: { vehicleId: vehicle.vehicleId }
	})

	const [updateSelectedEv] = useMutation(UPDATE_SELECTED_EV, {
		onCompleted: data => {
			setSelectedAlternativeElectricVehicle(
				data.updateSelectedEv?.selectedEv,
			);
			if (refetchOverviewData) refetchOverviewData();
			refetchEvAlternatives();
			getVehicleSelectedEv({
				variables: {
					vehicleId: vehicle.vehicleId,
					renewCalculation: false,
				},
			});
			toast.success('EV selected successfully');
		},
	});

	const [addNewFavoriteVehicleMutation] = useMutation(ADD_NEW_FAVORITE_EV, {
		onCompleted: () => {
			refetchFavorites();
			toast.success(t("favorite.addedToast"));
		},
	});

	const [removeFavoriteVehicleMutation] = useMutation(REMOVE_FAVORITE_EV, {
		onCompleted: () => {
			refetchFavorites();
			toast.success(t("favorite.removedToast"));
		},
	});

	const removeFavoriteVehicle = (evIdToRemove: string) => {
		setTogglingFavoriteEvId(evIdToRemove);
		removeFavoriteVehicleMutation({
			variables: {
				vehicleId: vehicle.vehicleId,
				evIdToRemove: evIdToRemove,
			},
		});
	};

	const addNewFavoriteVehicle = (evIdToAdd: string) => {
		setTogglingFavoriteEvId(evIdToAdd);
		addNewFavoriteVehicleMutation({
			variables: {
				vehicleId: vehicle.vehicleId,
				evIdToAdd: evIdToAdd,
			},
		});
	};

	const removeAlternative = () => {
		updateSelectedEv({
			variables: {
				vehicleId: vehicle.vehicleId,
				selectedEvId: null,
			},
		});
	};

	const selectAlternative = (selectedEvId: string) => {
		updateSelectedEv({
			variables: {
				vehicleId: vehicle.vehicleId,
				selectedEvId: selectedEvId,
			},
		});


		setIsAlternativesOpen(false);
	};

	useEffect(() => {
		if (vehicle.simulationIsReady && vehicle.isVehiclePaid) {
			getVehicleSelectedEv({
				variables: {
					vehicleId: vehicle.vehicleId,
					renewCalculation: false,
				},
			});
		}
	}, [vehicle.vehicleId]);

	return (
		<VehicleComparatorContext.Provider
			value={{
				evFinderData,
				evFinderFavoritesData,
				loading,
				sortBy,
				setSortBy,
				currentPage,
				setCurrentPage,
				favoritesOnly,
				setFavoritesOnly,
				alternativeEvsCount,
				filtersHandler,
				refetchEvAlternatives,
				resetFiltersAndRefetch,
				refetchFavorites,
				recountVehicles,
				refetchOverviewData,
				planUpgradeModalOpen,
				setPlanUpgradeModalOpen,
				selectedVehicleToUpgrade,
				setSelectedVehicleToUpgrade,
				isAlternativesOpen,
				setIsAlternativesOpen,
				evDetailQueryParams,
				setEvDetailQueryParams,
				getVehicleSelectedEv,
				selectedEvLoading,
				vehicle,
				vehicleSettings: data?.vehicleSettings,
				selectAlternative,
				selectedAlternativeElectricVehicle,
				removeAlternative,
				refetchSimulationVehiclesInfo,
				addNewFavoriteVehicle,
				removeFavoriteVehicle,
				togglingFavoriteEvId
			}}
		>
			{children}
		</VehicleComparatorContext.Provider>
	);
}
