import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";

/* Local imports */
import VehicleDeleteModal from "./VehicleEditor/DeleteVehicleModal";
import VehicleRestoreModal from "./VehicleEditor/RestoreVehicleModal";

// import { useModal } from "components/Modal/ModalService";
import { ReactComponent as HasHomeCharging } from "../../../../../assets/has-home-charging.svg";
import { ReactComponent as NoHomeCharging } from "../../../../../assets/no-home-charging.svg";
import { ReactComponent as OptionsDropdownIcon } from "../../../../../assets/options-dropdown-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/deleted-icon.svg";
import { ReactComponent as ArchivedIcon } from "../../../../../assets/archived-icon.svg";
import { ReactComponent as LowDataQualityIcon } from "../../../../../assets/low-data-quality-icon.svg";


import OnboardingModal from "../Onboarding/OnboardingModal";
import VehicleEditor from "./VehicleEditor";

import { VehicleSummary } from "../../../../../@types";

import { PaymentProcessModal } from "../../../../../common/components/PaymentProcess/PaymentProcessModal";
import Dropdown from "../../../../../electrify_frontend_common/components/Dropdown";
import { useVehicles } from "./useVehicles";
import { useNavigate } from "react-router-dom";


export default function Vehicles() {
  // const showModal = useModal();

  const navigate = useNavigate();

  const {
    t,
    localeCode,
    sortBy,
    vehiclesSummaryData,
    setVehicleToEdit,
    vehicleToDelete,
    vehicleToRestore,
    planUpgradeModalOpen,
    dataSyncModalOpen,
    setSortBy,
    archiveVehicle,
    unArchiveVehicle,
    deleteSelectedVehicle,
    restoreSelectedVehicle,
    setVehicleToDelete,
    setVehicleToRestore,
    setPlanUpgradeModalOpen,
    refetchVehicleSummaryData,
    vehicleToEdit,
    setDataSyncModalOpen
  } = useVehicles();

  const allVehiclesSummary = vehiclesSummaryData?.vehiclesSummary;
  const vehiclesCount = allVehiclesSummary?.length;

  const tableHeaders = [
    { property: "vehicleName", title: t(`vehicle`) },
    { property: "vehicleModelName", title: t(`model`) },
    { property: "totalDistance", title: t(`distance`) },
    { property: "earliestTripDate", title: t(`first-trip`) },
    { property: "latestTripDate", title: t(`last-trip`) },
    { property: "hasHomeCharging", title: t(`home-charging`) },
    { property: "tariffName", title: t(`chargylize-plan`) },
  ];



  const renderVehicleStatus = (vehicle: VehicleSummary) => {
    if (vehicle?.deletionDate)
      return {
        icon: <DeleteIcon />,
        style: "bg-Light-purple text-Blueberry-dark-default text-xs",
        tooltip:
          t("tooltips.deleted") +
          new Date(vehicle.deletionDate).toLocaleDateString(localeCode) +
          ".",
      };
    else if (vehicle?.archived)
      return {
        icon: <ArchivedIcon />,
        style: "bg-Grey-background text-Grey-default text-xs",
        tooltip: t("tooltips.archived"),
      };
    else if (vehicle?.qualityDataAmount === "Low")
      return {
        icon: <LowDataQualityIcon />,
        style: "text-xs text-Grey-dark",
        tooltip: t("tooltips.low-data-quality-amount"),
      };
    else if (vehicle?.qualityDataAmount === "Medium")
      return {
        icon: <LowDataQualityIcon />,
        style: "text-xs text-Grey-dark",
        tooltip: t("tooltips.medium-data-quality-amount"),
      };
    else if (vehicle?.qualityContinuity === "Low")
      return {
        icon: <LowDataQualityIcon />,
        style: "text-xs text-Grey-dark",
        tooltip: t("tooltips.low-data-continutity"),
      };
    else if (vehicle?.qualityContinuity === "Medium")
      return {
        icon: <LowDataQualityIcon />,
        style: "text-xs text-Grey-dark",
        tooltip: t("tooltips.medium-data-continutity"),
      };
    return {
      icon: undefined,
      style: "text-xs text-Grey-dark",
      Tooltip: undefined,
    };
  };

  const vehicleDropdownOptions = (vehicle: VehicleSummary) => {
    const result = [
      {
        key: t("dropdown-options.edit"),
        onClick: () => setVehicleToEdit(vehicle?.vehicleId),
      },
    ];

    if (vehicle?.deletionDate) {
      result.push({
        key: t("dropdown-options.restore"),
        onClick: () => setVehicleToRestore(vehicle),
      });
    } else {
      result.push({
        key: t("dropdown-options.delete"),
        onClick: () => setVehicleToDelete(vehicle),
      });
    }

    if (vehicle?.archived) {
      result.push({
        key: t("dropdown-options.unarchive"),
        onClick: () => unArchiveVehicle(vehicle),
      });
    } else if (!vehicle?.archived && !vehicle?.deletionDate) {
      result.push({
        key: t("dropdown-options.archive"),
        onClick: () => archiveVehicle(vehicle),
      });
    }

    return result;
  };

  return (
    <div>
      {planUpgradeModalOpen ? (
        <PaymentProcessModal
          open={planUpgradeModalOpen}
          onCancel={() => setPlanUpgradeModalOpen(false)}
          onFinalizeProcess={async () => {
            setPlanUpgradeModalOpen(false);
            await refetchVehicleSummaryData();
          }}
        />
      ) : null}

      {dataSyncModalOpen ? (
        <OnboardingModal
          startingStep={0}
          isOpen={dataSyncModalOpen}
          goToPageProps={{
            action: () => navigate("/vehicles"),
            text: "Go to vehicles"
          }}
          onCancel={() => {
            setDataSyncModalOpen(false);
            refetchVehicleSummaryData();
          }}
        />
      ) : null}

      {vehicleToEdit ? (
        <VehicleEditor
          vehicleId={vehicleToEdit}
          onClose={() => {
            setVehicleToEdit(null);
            refetchVehicleSummaryData();
          }}
        />
      ) : null}

      {vehicleToDelete && (
        <VehicleDeleteModal
          isOpen={!!vehicleToDelete}
          onDelete={() => deleteSelectedVehicle(vehicleToDelete)}
          closeModal={() => setVehicleToDelete(null)}
          onArchive={() => archiveVehicle(vehicleToDelete)}
        />
      )}

      {vehicleToRestore && (
        <VehicleRestoreModal
          onRestore={() => restoreSelectedVehicle(vehicleToRestore)}
          closeModal={() => setVehicleToRestore(null)}
        />
      )}

      {vehiclesCount > 0 ? (
        <Table
          className="mt-4 px-24"
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow hover={false} className="sticky top-0 bg-white">
              {tableHeaders?.map((item, i) => (
                <TableCell
                  key={i}
                  align={"center"}
                  padding={"normal"}
                  sortDirection={false}
                >
                  <TableSortLabel
                    active={sortBy.sortByColumn === item?.property}
                    direction={
                      sortBy.sortByColumn === item?.property
                        ? sortBy.sortAscending === 1
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    onClick={() =>
                      setSortBy({
                        sortByColumn: item?.property,
                        sortAscending:
                          sortBy.sortByColumn === item?.property
                            ? -1 * sortBy.sortAscending
                            : 1,
                      })
                    }
                  >
                    <span className="text-xs font-bold text-Grey-dark">
                      {item?.title}
                    </span>
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell
                key={1}
                align={"center"}
                padding={"normal"}
                sortDirection={false}
              ></TableCell>
              <TableCell
                key={2}
                align={"center"}
                padding={"normal"}
                sortDirection={false}
              ></TableCell>
            </TableRow>
          </TableHead>
          {allVehiclesSummary?.map(
            (vehicleSummary: VehicleSummary, i: number) => {
              const vehicleStatus = renderVehicleStatus(vehicleSummary);
              const vehicleStyle = vehicleStatus?.style;
              return (
                <TableRow hover={true} key={`${vehicleSummary?.vehicleId}`}>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.vehicleName || "-"}
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.vehicleModelName || "-"}
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.totalDistance?.toLocaleString(localeCode) +
                      " km"}
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.earliestTripDate
                      ? new Date(
                        vehicleSummary?.earliestTripDate
                      ).toLocaleDateString(localeCode)
                      : "-"}
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.latestTripDate
                      ? new Date(
                        vehicleSummary.latestTripDate
                      ).toLocaleDateString(localeCode)
                      : "-"}
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    <span className="flex justify-center">
                      {vehicleSummary?.hasHomeCharging ? (
                        <Tooltip
                          title={t("tooltips.has-home-charger")}
                          arrow
                          placement="top-start"
                        >
                          {<HasHomeCharging />}
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={t("tooltips.has-no-home-charger")}
                          arrow
                          placement="top-start"
                        >
                          {<NoHomeCharging />}
                        </Tooltip>
                      )}
                    </span>
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.tariffName || "-"}
                  </TableCell>
                  <TableCell
                    align={"right"}
                    // padding={'normal'}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleStatus?.tooltip ? (
                      <Tooltip
                        title={vehicleStatus?.tooltip}
                        arrow
                        placement="top-start"
                      >
                        <>{vehicleStatus?.icon}</>
                      </Tooltip>
                    ) : (
                      vehicleStatus?.icon
                    )}
                  </TableCell>
                  <TableCell
                    align={"right"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    <Dropdown
                      className={"w-16"}
                      placeholder={
                        <span className="flex justify-end">
                          <OptionsDropdownIcon />
                        </span>
                      }
                      data={vehicleDropdownOptions(vehicleSummary)}
                      loadUpwards={vehiclesCount > 8 && i >= vehiclesCount - 2}
                    />
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </Table>
      ) : null}
    </div>
  );
}
