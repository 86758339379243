
import { SimulationVehicleInfo } from "../../../../../../../@types";
import { EvDetailedViewModal } from "../../../../../../../common/components/VehicleSimulationAndSelection/EvDetailedViewModal";
import { Spinner } from "../../../../../../../electrify_frontend_common/components/Spinner";
import { useContext } from "react";
import { DriverEVFinderContext } from "../contexts/DriverEVFinderContext";
import { DriverEvFinderFilters } from "./DriverEvFinderFilters";
import { DriverEvFinderVehicleList } from "./DriverEvFinderVehiclesList";
import { EvFinderControlPanel } from "../../../../../../../common/components/EVFinder/Versions[B-C]";
import { PaginationSelector } from "../../../../../../../electrify_frontend_common/components/EvFinder/PaginationSelector";





export function DriverVehicleAlternatives({
    vehicle,
}: {
    vehicle: SimulationVehicleInfo,
}) {


    const vehicleId = vehicle?.vehicleId;

    const {
        evFinderData,
        loading,
        currentPage,
        setCurrentPage,
        favoritesOnly,
        evDetailQueryParams,
        closeEvDetailsModal,
        alternativeEvsCount,
        sortBy,
        setSortBy,
        setFavoritesOnly,
    } = useContext(DriverEVFinderContext);

    const getVehiclesCount = () => ({
        totalCount:
            alternativeEvsCount?.vehicleEvAlternativesCount?.totalCount,
        favoritesCount:
            alternativeEvsCount?.vehicleEvAlternativesCount?.favoritesCount,
    });
   

    return (
        <>
            {evDetailQueryParams ? <EvDetailedViewModal isOpen={!!evDetailQueryParams} onCancel={closeEvDetailsModal} vehicleId={vehicleId} evDetailQueryParams={evDetailQueryParams} /> : null}
            < >
                <div className="flex">
                    <div className={`h-full ${favoritesOnly ? 'w-full' : 'w-9/12'}`}>
                        <EvFinderControlPanel
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                            alternativesCount={getVehiclesCount().totalCount}
                            favoritesCount={getVehiclesCount().favoritesCount}
                            favoritesOnly={favoritesOnly}
                            setFavoritesOnly={setFavoritesOnly}
                        />

                        {loading && !evFinderData ? (
                            <Spinner />
                        ) : (
                            <DriverEvFinderVehicleList userVehicle={vehicle}/>
                        )}
                    </div>

                    {!favoritesOnly ? <div className="pl-4 pt-10 max-w-xs w-3/12">
                        <DriverEvFinderFilters />
                    </div>
                        : null}

                </div>
                {
                    !favoritesOnly ? <PaginationSelector
                        currentPage={currentPage}
                        setPage={setCurrentPage}
                        totalPagesCount={evFinderData?.vehicleEvAlternatives?.totalPagesCount || 0}
                    /> : null
                }

            </>
        </>
    )


}