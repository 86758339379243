import { Dispatch, SetStateAction } from "react";
import { ReactComponent as EnergyIcon } from "../../../../../../../assets/energy-icon.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as InformationIcon } from "../../../../../../../assets/information-icon.svg";
import { Tooltip } from "../../../../../../../electrify_frontend_common/components/Tooltip";
import { useLayout } from "../../../../../../../hooks/state/useLayout";


const valueIsNumeric = (v: number | null | undefined): boolean => {
  return v !== null && v !== undefined && !isNaN(v);
}


export function InputHeader() {
  const { t } = useTranslation("settingsSimulation");

  return (
    <div className="flex justify-around mb-4">
      <span className="text-sm font-bold text-Grey-dark">
        {t("fuel-energy-table.energy.energy")}
      </span>
      <div className="flex items-center">
        <span className="text-sm font-bold text-Grey-dark">
          {t("fuel-energy-table.energy.location")}
        </span>
        <Tooltip
          content={<div>{t("fuel-energy-table.energy.location-tooltip")}</div>}
          placement="right-bottom"
        >
          <InformationIcon className="ml-1" />
        </Tooltip>
      </div>
    </div>
  );
}

export function EnergySettingsInput({
  label,
  units,
  value,
  setValue,
  defaultValue,
  reversed,
  onBlur,
}: {
  label: string;
  units: string;
  defaultValue: number;
  value: number | undefined | null;
  setValue: Dispatch<SetStateAction<number | null | undefined>>;
  reversed?: boolean;
  onBlur?: () => void;
}) {
  const { localeCode } = useLayout();


  return (
    <div className="flex justify-center items-center w-full mb-2">
      {reversed ? (
        <span className="w-1/2 text-sm justify-items-center pl-7 font-bold text-Grey-default">
          {label}
        </span>
      ) : null}
      <div className="flex items-center border border-solid border-Grey-tint text-xs text-center rounded h-10 w-1/2 py-1  bg-white">
        <input
          className="text-center focus:ring-0 w-4/6 h-3/4  text-black border-none"
          lang={localeCode}
          type="number"
          min="0"
          step="0.01"
          value={valueIsNumeric(value) ? value as number : ""}
          placeholder={defaultValue.toLocaleString(localeCode)}
          onInput={(e: any) => setValue(parseFloat(e.target.value))}
          onBlur={() => {
            if (onBlur) onBlur();
          }}
        />
        <div
          className={`ml-[-20px] text-md z-20 ${valueIsNumeric(value) ? "text-black" : "text-Grey-default"
            }`}
        >
          {units}
        </div>
      </div>
      {!reversed ? (
        <span className="w-1/2 text-sm justify-items-center pl-7 font-bold text-Grey-default">
          {label}
        </span>
      ) : null}
    </div>
  );
}

export function EnergyDetails({
  energyAndFuelDetails,
}: {
  energyAndFuelDetails: any;
}) {

  const { t } = useTranslation("settingsSimulation");

  if (!energyAndFuelDetails.defaultPrices) return null;

  const priceLabel = `${energyAndFuelDetails.defaultPrices.currencySymbol} / kWh`;



  return (
    <div className="flex flex-col w-full rounded-md border border-Grey-tint  bg-Grey-background">
      <div className="h-2 rounded-t-md bg-Blueberry-dark-default" />
      <div className="flex flex-col  items-center py-4">
        <EnergyIcon />
        <span className="mt-2 text-lg font-medium">
          {t("fuel-energy-table.energy.header")}
        </span>
      </div>
      <div className="py-4 px-5 flex flex-col  items-center border-t border-Grey-tint">
        <span className="font-medium text-lg mb-6">
          {t("fuel-energy-table.price")}
        </span>
        <div className="flex flex-col w-full justify-between pb-2">
          <InputHeader />
          <EnergySettingsInput
            onBlur={energyAndFuelDetails.saveFuelSettings}
            label={t("fuel-energy-table.energy.home")}
            units={priceLabel}
            value={energyAndFuelDetails.homeChargingPrice}
            setValue={energyAndFuelDetails.setHomeChargingPrice}
            defaultValue={energyAndFuelDetails.defaultPrices.homeChargingPrice}
          />
          <EnergySettingsInput
            onBlur={energyAndFuelDetails.saveFuelSettings}
            label={`${t("fuel-energy-table.energy.public")} AC`}
            units={priceLabel}
            value={energyAndFuelDetails.publicAcChargingPrice}
            setValue={energyAndFuelDetails.setPublicAcChargingPrice}
            defaultValue={
              energyAndFuelDetails.defaultPrices.publicAcChargingPrice
            }
          />
          <EnergySettingsInput
            onBlur={energyAndFuelDetails.saveFuelSettings}
            label={`${t("fuel-energy-table.energy.public")} DC`}
            units={priceLabel}
            value={energyAndFuelDetails.publicDcChargingPrice}
            setValue={energyAndFuelDetails.setPublicDcChargingPrice}
            defaultValue={
              energyAndFuelDetails.defaultPrices.publicDcChargingPrice
            }
          />
        </div>
      </div>
      <div className="py-4 px-5 flex flex-col  items-center border-t border-Grey-tint">
        <span className="font-medium text-lg mb-6">
          {t("fuel-energy-table.emissions")}
        </span>
        <div className="flex flex-col w-full justify-between ">
          <InputHeader />
          <EnergySettingsInput
            onBlur={energyAndFuelDetails.saveFuelSettings}
            label={t("fuel-energy-table.energy.home")}
            units={"g / kWh"}
            value={energyAndFuelDetails.homeChargingCo2}
            setValue={energyAndFuelDetails.setHomeChargingCo2}
            defaultValue={
              energyAndFuelDetails.defaultPrices.homeChargingCo2Emissions
            }
          />
          <EnergySettingsInput
            onBlur={energyAndFuelDetails.saveFuelSettings}
            label={t("fuel-energy-table.energy.public")}
            units={"g / kWh"}
            value={energyAndFuelDetails.publicChargingCo2}
            setValue={energyAndFuelDetails.setPublicChargingCo2}
            defaultValue={
              energyAndFuelDetails.defaultPrices.publicChargingCo2Emissions
            }
          />
        </div>
      </div>
    </div>
  );
}
