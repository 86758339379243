import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { VehicleSummary } from "../../../../../@types";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { GET_VEHICLES_SUMMARY } from "../../../../../services/graphql/fleet/queries";
import { DELETE_VEHICLE, RESTORE_VEHICLE, UPDATE_VEHICLE } from "../../../../../services/graphql/fleet/mutations";
import { ReactComponent as PlusIcon } from "../../../../../assets/plus-icon.svg";
import { ReactComponent as UpgradePlanIcon } from "../../../../../assets/upgrade-plan-icon.svg";
import { useQuery, useMutation } from "@apollo/client";

export function useVehicles(): {
    t: any;
    localeCode: string;
    vehiclesSummaryData: { vehiclesSummary: VehicleSummary[] }
    editorOpen: boolean;
    planUpgradeModalOpen: boolean;
    dataSyncModalOpen: boolean;
    setVehicleToEdit: Dispatch<SetStateAction<string | null>>;
    vehicleToDelete: VehicleSummary | null;
    vehicleToRestore: VehicleSummary | null;
    sortBy: { sortByColumn: string; sortAscending: number };
    setSortBy: (value: { sortByColumn: string; sortAscending: number }) => void,
    archiveVehicle: (vehicle: VehicleSummary) => void,
    unArchiveVehicle: (vehicle: VehicleSummary) => void,
    deleteSelectedVehicle: (vehicle: VehicleSummary) => void,
    restoreSelectedVehicle: (vehicle: VehicleSummary) => void,
    setPlanUpgradeModalOpen: Dispatch<SetStateAction<boolean>>;
    setVehicleToRestore: Dispatch<SetStateAction<VehicleSummary | null>>;
    setVehicleToDelete: Dispatch<SetStateAction<VehicleSummary | null>>;
    refetchVehicleSummaryData: () => void;
    vehicleToEdit: string | null;
    setDataSyncModalOpen: Dispatch<SetStateAction<boolean>>;

} {

    const { t, i18n } = useTranslation("vehicles");
    const language = i18n.language;
    const { setHeader, localeCode } = useLayout();

    const [editorOpen, setEditorOpen] = useState(false);

    const [planUpgradeModalOpen, setPlanUpgradeModalOpen] = useState(false);
    const [dataSyncModalOpen, setDataSyncModalOpen] = useState(false);
    const [vehicleToEdit, setVehicleToEdit] = useState<string | null>(null);
    const [vehicleToDelete, setVehicleToDelete] = useState<VehicleSummary | null>(
        null
    );
    const [vehicleToRestore, setVehicleToRestore] =
        useState<VehicleSummary | null>(null);
    const [sortBy, setSortBy] = useState({
        sortByColumn: "vehicleName",
        sortAscending: 1,
    });

    useEffect(() => {
        if (vehicleToEdit) setEditorOpen(true);
    }, [vehicleToEdit]);



    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t(`page-title`),
            defaultHeaderDescription: t(`page-subtitle`),
            defaultHeaderOptions: (
                <div className="flex">
                    <button
                        className="text-Grey-dark text-sm	px-4 py-3 mr-2 border border-solid border-Grey-tint rounded flex items-center"
                        onClick={() => {
                            setDataSyncModalOpen(true);
                            // window.location.href = "/electrify/onboarding";
                        }}
                    >
                        <PlusIcon className="mr-2" />
                        {t(`add-vehicle-button`)}
                    </button>
                    <button
                        className="text-white text-sm	leading-6	px-4 py-3 mr-2 flex items-center bg-Blueberry-dark-default rounded"
                        onClick={() => setPlanUpgradeModalOpen(true)}
                    >
                        <UpgradePlanIcon className="mr-2" />
                        {t(`upgrade-plan-button`)}
                    </button>
                </div>
            ),
        });
    }, [language]);

    const { data: vehiclesSummaryData, refetch: refetchVehicleSummaryData } =
        useQuery(GET_VEHICLES_SUMMARY, {
            variables: {
                sortBy: sortBy?.sortByColumn,
                sortAscending: sortBy?.sortAscending,
            },
            onError: (error) => console.log("ERRRR>>>", error),
        });

    const [deleteVehicle] = useMutation(DELETE_VEHICLE, {
        update(cache, { data: { deleteVehicle } }) {
            cache.modify({
                fields: {
                    vehicles(existingVehicles = []) {
                        return [
                            ...existingVehicles.filter(
                                (item: any) => item.__ref !== "Vehicle:" + deleteVehicle.id
                            ),
                        ];
                    },
                },
            });
        },
    });

    const [updateVehicle] = useMutation(UPDATE_VEHICLE);

    const [restoreVehicle] = useMutation(RESTORE_VEHICLE, {
        onCompleted: () => setVehicleToRestore(null),
        onError: (error) => console.log("ERRRR>>>", error),
    });



    const archiveVehicle = async (vehicle: VehicleSummary) => {
        await updateVehicle({
            variables: {
                id: vehicle?.vehicleId,
                vehicle: { archived: true },
            },
        });
        setVehicleToDelete(null);
        refetchVehicleSummaryData();
    };

    const unArchiveVehicle = async (vehicle: VehicleSummary) => {
        await updateVehicle({
            variables: {
                id: vehicle?.vehicleId,
                vehicle: { archived: false },
            },
        });
        refetchVehicleSummaryData();
    };

    const deleteSelectedVehicle = async (vehicle: VehicleSummary) => {
        await deleteVehicle({
            variables: {
                id: vehicle.vehicleId,
            },
        });
        setVehicleToDelete(null);
        refetchVehicleSummaryData();
    };

    const restoreSelectedVehicle = async (vehicle: VehicleSummary) => {
        await restoreVehicle({
            variables: {
                id: vehicle.vehicleId,
            },
        });
        setVehicleToDelete(null);
        refetchVehicleSummaryData();
    };


    return {
        t,
        localeCode,
        editorOpen,
        vehiclesSummaryData,
        setVehicleToEdit,
        vehicleToDelete,
        vehicleToRestore,
        planUpgradeModalOpen,
        dataSyncModalOpen,
        sortBy,
        setSortBy,
        archiveVehicle,
        unArchiveVehicle,
        deleteSelectedVehicle,
        restoreSelectedVehicle,
        setPlanUpgradeModalOpen,
        setVehicleToDelete,
        setVehicleToRestore,
        refetchVehicleSummaryData,
        vehicleToEdit,
        setDataSyncModalOpen
    }
}