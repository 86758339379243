import { useMemo, useState } from "react";
import { getImageServerWithFallback } from "../../../../utils";

function NoBrandImagePlaceHolder() {
    return <div className="w-10 h-10 rounded-full bg-Grey-light bg-opacity-40 mr-3" />
}

function createBrandLogoUrl(vehicleBrand: string): string {
    const newParams = new URLSearchParams();
    newParams.append("name", vehicleBrand);

    return `${getImageServerWithFallback()}/images/logo/oem?${newParams.toString()}`;
}

export function BrandInfo({ vehicleBrand, vehicleModel }: {
    vehicleBrand?: string | null;
    vehicleModel?: string | null;
}) {

    const [displayBrandImage, setDisplayBrandImage] = useState(true);
    const replaceBrandImgWithEPlaceholder = () => setDisplayBrandImage(false);

    const logoUri = useMemo(() => {
        if (vehicleBrand)
            return createBrandLogoUrl(vehicleBrand);

        return null;
    }, [vehicleBrand]);

    return (
        <div className="flex items-start">
            {displayBrandImage && logoUri ? <img
                className="mr-3 w-10 object-contain aspect-square"
                src={logoUri}
                loading="lazy"
                onError={replaceBrandImgWithEPlaceholder}
                alt="vehicle-brand"
            />
                : <NoBrandImagePlaceHolder />}
            <div>
                <div className="text-xs text-Grey-default">{vehicleBrand}</div>
                <div className="text-sm text-black">{vehicleModel}</div>
            </div>
        </div>
    );
}
