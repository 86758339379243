import { useState } from "react";

import { useTranslation } from "react-i18next";
import { ManualVehicleInput } from "../../../../../../../common/components/VehicleEditor/ManualVehicleInput";
import { VinVehicleInput } from "../../../../../../../common/components/VehicleEditor/VinVehicleInput";
import { Vehicle } from "../../../../../../../@types/vehicle";
import ModalWrapper from "../../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { UPDATE_VEHICLE } from "../../../../../../../services/graphql/fleet/mutations";

export enum InputTypes {
    VIN = "VIN",
    MANUAL = "MANUAL"
};

export function DriverVehicleEditor({ isOpen, close, vehicle, defaultInput }: { isOpen: boolean, close: () => void, vehicle?: Vehicle, defaultInput?: InputTypes }) {

    const [selectedVehicleInfoInput, setSelectedVehicleInfoInput] = useState<InputTypes | null>(defaultInput ? defaultInput : (!vehicle?.modelInfo?.useVin ? InputTypes.MANUAL : InputTypes.VIN));

    const { t } = useTranslation("editVehicle");

    const VehicleInputs = {
        [InputTypes.MANUAL]: <ManualVehicleInput
            vehicleData={vehicle}
            mutation={UPDATE_VEHICLE}
            onMutationSuccessful={close}
        />,
        [InputTypes.VIN]: <div className="w-1/3"><VinVehicleInput
            onSuccessUpdate={close}
            vehicleData={vehicle}
            mutation={UPDATE_VEHICLE}
        /></div>
    }

    return (

        <ModalWrapper
            isOpen={isOpen}
            close={close}
            className="w-2/3">
            <>
                <ModalHeader title="Specify your vehicle" onCancel={close} isLastStep />
                <div className="max-h-full overflow-y-auto">
                    <div className="w-full p-10">
                        <h2 className="text-xl mb-4">{t("vehicle-info-label")}</h2>
                        <div className="w-2/3">
                            <span className="text-sm text-Grey-dark">{t("vehicle-info-message")}</span>
                        </div>
                        <div className="flex mt-5 mb-6">
                            <Button
                                onClick={() => setSelectedVehicleInfoInput(InputTypes.VIN)}
                                variant={selectedVehicleInfoInput === InputTypes.VIN ? ButtonVariant.PRIMARY : ButtonVariant.SECONDRY}
                                size={ButtonSize.MEDIUM}
                                className="mr-6"
                            >
                                {t("add-vin-number")}
                            </Button>
                            <Button
                                onClick={() => setSelectedVehicleInfoInput(InputTypes.MANUAL)}
                                variant={selectedVehicleInfoInput === InputTypes.MANUAL ? ButtonVariant.PRIMARY : ButtonVariant.SECONDRY}
                                size={ButtonSize.MEDIUM}
                            >
                                {t("add-manually")}
                            </Button>
                        </div>

                        {
                            selectedVehicleInfoInput ?
                                <>{VehicleInputs[selectedVehicleInfoInput]}</>
                                : null
                        }
                    </div>
                </div>
            </>
        </ModalWrapper>

    )



}