import { ReactElement, useState } from 'react';
import { NoData } from '../components';
import { ReactComponent as NoHomeAddresses } from '../../../../../../assets/home-location-img.svg';
import { ReactComponent as NoWorkplaceAddresses } from '../../../../../../assets/workplace-location-img.svg';
import { ApolloQueryResult, OperationVariables, useQuery } from '@apollo/client';

import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from '../../../../../../assets/plus-icon.svg';
import AddressCard from '../../../../../../common/components/Address/AddressCard';
import { Location } from '../../../../../../@types/settings';
import { Vehicle } from '../../../../../../@types/vehicle';
import { GET_ADDRESSES } from '../../../../../../services/graphql/fleet/queries';
import LocationModal from '../../../../../../common/components/Address/AddressCard/modals/LocationModal';
import { Button } from '../../../../../../electrify_frontend_common/components/Buttonv2';
import { ButtonSize, ButtonVariant } from '../../../../../../electrify_frontend_common/components/Buttonv2/types';

function LocationMenu({
	title,
	locationData,
	refetchAddresses,
	locationType,
	noLocationFallback,
}: {
	title: string;
	locationData?: { addresses: Location[] };
	refetchAddresses: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{
		addresses: Location[];
	}>>;
	locationType: 'HOME' | 'WORKPLACE';
	noLocationFallback: ReactElement;
}) {
	const hasLocations = locationData?.addresses.length;

	return (
		<>
			<section className="mt-10">
				<div className="flex justify-between items-center mb-4">
					<h1 className="text-xl">{title}</h1>
				</div>
				<div>
					{hasLocations ? (
						<div className="flex flex-col space-y-6 mb-10">
							<div className="col-span-6">
								<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-3 grid-flow-row gap-8">
									{locationData?.addresses?.map(
										(item: Location) => {
											return (
												<AddressCard
													address={item}
													key={item.id}
													refetch={refetchAddresses}
													locationType={locationType}
												/>
											);
										},
									)}
								</div>
							</div>
						</div>
					) : (
						<>{noLocationFallback}</>
					)}
				</div>
			</section>
		</>
	);
}

export function Locations({ vehicle }: { vehicle?: Vehicle }) {
	const { t } = useTranslation('driverSettings');

	const [locationType, setLocationType] = useState<
		'HOME' | 'WORKPLACE' | null
	>(null);

	const { data: homeLocations, refetch: refetchHomeLocations } = useQuery<{
		addresses: Location[];
	}>(GET_ADDRESSES, {
		variables: {
			locationType: 'HOME',
			vehicleId: vehicle?.id,
		},
		onCompleted: (data) => {
			console.log("UPDATED ");
		
		}
	});

	const { data: workplaceLocations, refetch: refetchWorkplaceLocations } =
		useQuery<{ addresses: Location[] }>(GET_ADDRESSES, {
			variables: {
				locationType: 'WORKPLACE',
				vehicleId: vehicle?.id,
			},
			onCompleted: (data) => {
				console.log("UPDATED WORK LOCATION : ", data);
			
			}
		});

	return (
		<>
			{locationType ? (
				<LocationModal
					isOpen={!!locationType}
					vehicleId={vehicle?.id}
					closeModal={() => {
						setLocationType(null);
						locationType == 'HOME'
							? refetchHomeLocations()
							: refetchWorkplaceLocations();
					}}
					setLocationType={(v: 'HOME' | 'WORKPLACE') => {
						setLocationType(v);
					}}
					locationType={locationType}
					isDriver={true}
				/>
			) : null}
			<div className="flex w-full justify-between items-center mt-10">
				<h1 className="text-2xl">{t('locations.title')}</h1>

				<Button
					onClick={() => setLocationType('HOME')}
					disabled={!vehicle}
					variant={ButtonVariant.TERTIARY}
					size={ButtonSize.MEDIUM}
				>
					<div className="flex items-center">
						<PlusIcon className="mr-2" />
						<span>{t('locations.add-button')}</span>
					</div>
				</Button>
			</div>

			<LocationMenu
				title={t('locations.home-title')}
				locationType="HOME"
				locationData={homeLocations}
				refetchAddresses={refetchHomeLocations}
				noLocationFallback={
					<NoData
						icon={<NoHomeAddresses />}
						title={t('locations.home-title')}
						text={t('locations.no-home-locations-text')}
						description={t(
							'locations.no-home-locations-description',
						)}
						buttonText={t(
							'locations.no-home-locations-button-text',
						)}
						onClick={() => setLocationType('HOME')}
					/>
				}
			/>
			<LocationMenu
				title={t('locations.work-title')}
				locationType="WORKPLACE"
				locationData={workplaceLocations}
				refetchAddresses={refetchWorkplaceLocations}
				noLocationFallback={
					<NoData
						icon={<NoWorkplaceAddresses />}
						title={t('locations.work-title')}
						text={t('locations.no-work-locations-text')}
						description={t(
							'locations.no-work-locations-description',
						)}
						buttonText={t(
							'locations.no-work-locations-button-text',
						)}
						onClick={() => setLocationType('WORKPLACE')}
					/>
				}
			/>
		</>
	);
}
