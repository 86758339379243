import { useTranslation } from "react-i18next";
import { ReactComponent as EnergyIcon } from "../../../../../../../assets/energy-icon.svg";
import { SettingsInput } from "../../../../../../B2B/Electrify/pages/Settings/FuelAndEnergyDetails/FuelDetails";

export function EnergyDetails({
  energyAndFuelDetails,
}: {
  energyAndFuelDetails: any;
}) {
  const { t } = useTranslation("settingsSimulation");

  if (!energyAndFuelDetails.defaultPrices) return null;

  const priceLabel = `${energyAndFuelDetails.defaultPrices.currencySymbol} / kWh`;

  return (
    <div className="flex flex-col w-full rounded-md border border-Grey-tint  bg-Grey-background">
      <div className="h-2 rounded-t-md bg-Blueberry-dark-default" />
      <div className="flex flex-col  items-center py-4">
        <EnergyIcon />
        <span className="mt-2 text-lg font-medium">
          {t("fuel-energy-table.energy.header")}
        </span>
      </div>
      <div className="py-4 px-5 flex flex-col  items-center border-t border-Grey-tint">
        <span className="font-medium text-lg mb-6">
          {t("fuel-energy-table.price")}
        </span>
        <div className="flex w-full justify-between pb-2">
          <SettingsInput
            saveSettings={energyAndFuelDetails.saveFuelSettings}
            label={`${t("fuel-energy-table.energy.public")} AC`}
            units={priceLabel}
            value={energyAndFuelDetails.publicAcChargingPrice}
            setValue={energyAndFuelDetails.setPublicAcChargingPrice}
            defaultValue={
              energyAndFuelDetails.defaultPrices.publicAcChargingPrice
            }
          />
          <SettingsInput
            saveSettings={energyAndFuelDetails.saveFuelSettings}
            label={`${t("fuel-energy-table.energy.public")} DC`}
            units={priceLabel}
            value={energyAndFuelDetails.publicDcChargingPrice}
            setValue={energyAndFuelDetails.setPublicDcChargingPrice}
            defaultValue={
              energyAndFuelDetails.defaultPrices.publicDcChargingPrice
            }
          />
        </div>
      </div>
      <div className="py-4 px-5 flex flex-col  items-center border-t border-Grey-tint">
        <span className="font-medium text-lg mb-6">
          {t("fuel-energy-table.emissions")}
        </span>
        <div className="flex flex-col w-1/2 justify-between ">
          <SettingsInput
            saveSettings={energyAndFuelDetails.saveFuelSettings}
            label={`${t("fuel-energy-table.energy.public")} CHARGING`}
            units={`g / kWh`}
            value={energyAndFuelDetails.publicChargingCo2}
            setValue={energyAndFuelDetails.setPublicChargingCo2}
            defaultValue={
              energyAndFuelDetails.defaultPrices.publicChargingCo2Emissions
            }
          />
        </div>
      </div>
    </div>
  );
}
